

export default function Footer() {
    
  return (
    <footer className="mil-dark-bg">
      <img src="assets/img/deco/map.png" alt="background" className="mil-footer-bg" />
      <div className="container">
        <div className="mil-footer-content mil-p-10-50">
          <div className="row justify-content-between align-items-center">
            <div className="col-xl-4 mil-mb-100">
              <div className="row ">
                <div className="col-lg-7 mil-mt-30">
                  <img src="assets/img/logo/logo-light.png" alt="" className="mil-logo mil-mb-30" style={{ width: '170px' }} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mil-divider mil-light"></div>
        <div className="mil-footer-links">
          <ul className="mil-social mil-light">
            <li className="mil-adapt-links"><a href="https://web.facebook.com/profile.php?id=61551252731959" target="_blank">Facebook</a><a href="https://web.facebook.com/profile.php?id=61551252731959" target="_blank">FB</a></li>
            <li className="mil-adapt-links"><a href="https://www.linkedin.com/in/zenith-solutions-mw-6a5b51292/" target="_blank">LinkedIn</a><a href="https://www.linkedin.com/in/zenith-solutions-mw-6a5b51292/" target="_blank">IN</a></li>
            {/* <li className="mil-adapt-links"><a href="#">Twitter</a><a href="#">TW</a></li> */}
          </ul>
        
        </div>
      </div>
      <div className="mil-footer-bottom">
        <div className="container">
          <p className="mil-text-sm mil-light">© Zenith.</p>
          <p className="mil-text-sm mil-light">All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
}