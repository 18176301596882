import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header';
import Footer from './components/Footer';
import NavScrollBack from './components/NavScrollBack'

import PageNotFound from './pages/404'
import Home from './pages/Home';
import About from './pages/About-Us';
import Contact from './pages/Contact-Us';
import Consulting from './pages/Consulting';
import SoftwareDevelopment from './pages/Software-Development';

const App = () => (
  <div className="mil-wrapper">
    <Router>
      <Header />
      <NavScrollBack/>

      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/About-Us' element={<About />} />
        <Route path='/Contact-Us' element={<Contact />} />
        <Route path='/Consulting' element={<Consulting />} />
        <Route path='/Software-Development' element={<SoftwareDevelopment />} />
        <Route path='*' element={<PageNotFound />} />

      </Routes>
      <Footer />
    </Router>
  </div>
);

export default App;
