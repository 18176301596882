import React, { useState, useEffect } from 'react';
import 'swiper/swiper-bundle.min.css';
import SwiperCore, { Parallax } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faChevronDown } from '@fortawesome/free-solid-svg-icons';

SwiperCore.use([Parallax]);

const About = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsVisible((prevVisible) => !prevVisible);
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div>
      <div className="mil-banner mil-top-space-0" style={{ height: '500px', position: 'relative' }}>
        <img
          src="assets/img/deco/hero8.jpg"
          className={`mil-background-image ${isVisible ? 'zoom-in-fade-in' : 'zoom-out-fade-out'}`}
          style={{ objectPosition: 'center', height: '500px', position: 'absolute', top: 0, left: 0, width: '100%' }}
          alt="image" />
        <div className="mil-overlay"></div>
        <div className="mil-banner-content mil-mt-10 ml-12">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-12 ml-10">
                <h2 className="mil-mb-40">
                  <span className="mil-uppercase mil-light">About Zenith</span>
                </h2>
                <h5 className="text-light mil-mb-60"></h5>
              </div>
            </div>
          </div>
        </div>
        <div className="scroll-down-arrow">
          <FontAwesomeIcon icon={faChevronDown} />
        </div>
      </div>
      <section className="mil-deep-bg mil-p-120-0">
        <div className="container">
          <Swiper parallax={true}>
            <SwiperSlide>
              <div className="row justify-content-between align-items-center" data-swiper-parallax-y="-100" data-swiper-parallax-opacity="0">
                <div className="col-lg-6">
                  <div className="mil-hori-box mil-mb-60">
                    <div className="mil-mr-15">
                      <div className="mil-icon-frame mil-icon-frame-md"></div>
                    </div>
                    <h5>Our Mission</h5>
                  </div>
                  <div className="row mil-mb-60">
                    <div className="col-lg-12">
                      <p>"To empower businesses with innovative technology solutions that drive growth, efficiency, and success."</p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="mil-hori-box mil-mb-60">
                    <div className="mil-mr-15">
                      <div className="mil-icon-frame mil-icon-frame-md"></div>
                    </div>
                    <h5>Our Goal</h5>
                  </div>
                  <div className="row mil-mb-60">
                    <div className="col-lg-12">
                      <p>
                      "To lead in technology innovation and excellence, providing cutting-edge solutions that empower clients to achieve business objectives efficiently and effectively."
                      </p>
                    </div>
                  </div>
                </div>
              </div>



              {/* Similar structure for other sections */}

            </SwiperSlide>
          </Swiper>
        </div>
      </section>
    </div>
  );
};

export default About;
