import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom'; // Replaced Link with NavLink
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const ServicePage = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [activeTab, setActiveTab] = useState('tab-1');

  useEffect(() => {
    const interval = setInterval(() => {
      setIsVisible((prevVisible) => !prevVisible);
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const tabContent = {
    'tab-1': {
      icon: '22.svg',
      text: 'At Zenith Solutions, we start every project with a thorough discovery and research phase. This helps us to understand your business goals, target audience, and the unique challenges and opportunities that your app will face.'
    },
    'tab-2': {
      icon: '25.svg',
      text: 'At Zenith Solutions, we have a team of experienced UX/UI designers who can create a custom design for your assets app that meets your specific needs and goals. We take the time to understand your target audience and your business goals, and we use this information to create a design that is both user-friendly and visually appealing.'
    },
    'tab-3': {
      icon: '20.svg',
      text: 'Once the UX/UI design is complete, we will begin the development phase of your app. This process involves writing the code for your app and bringing it to life. We use a variety of programming languages and technologies to develop  apps, depending on the specific needs of your app. We also follow best practices and coding standards to ensure that your app is high-quality and easy to maintain.'
    },
    'tab-4': {
      icon: '21.svg',
      text: 'Once the development phase is complete, we will begin the QA phase. This involves testing your app thoroughly to identify and fix any bugs. We also test your app on a variety of devices and operating systems to ensure that it works properly for all of your users. We use a variety of QA tools and techniques to test your app, including manual testing, automated testing, and usability testing. We also work with you to identify and prioritize any bugs that are found.'
    },
    'tab-5': {
      icon: '15.svg',
      text: 'Once your app has been thoroughly tested, we will help you to launch it on the app stores. This process involves submitting your app to the app stores and working with them to get your app approved. We can also help you to promote your app and get it downloaded by as many users as possible.'
    },
    'tab-6': {
      icon: '10.svg',
      text: 'We will continue to provide maintenance and support. This includes fixing any bugs that are found after launch and adding new features and functionality to your app. We also offer a variety of other support services, such as user training and technical support. We understand that your  app is an important investment, and we are committed to providing you with the best possible development, QA, launch, and maintenance services.'
    }
  };

  return (
    <div className="mil-wrapper">
      <div className="mil-banner mil-top-space-0" style={{ height: '500px', position: 'relative' }}>
        <img
          src="assets/img/deco/hero1.jpg"
          className={`mil-background-image ${isVisible ? 'zoom-in-fade-in' : 'zoom-out-fade-out'}`}
          style={{ objectPosition: 'center', height: '500px', position: 'absolute', top: 0, left: 0, width: '100%' }}
          alt="image"
        />
        <div className="mil-overlay"></div>
        <div className="mil-banner-content mil-mt-10 ml-12">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-12 ml-10">
                <h2 className="mil-mb-40">
                  <span className="mil-uppercase mil-light">Modern Development</span>
                </h2>
                <h5 className="text-light mil-mb-60"></h5>
              </div>
            </div>
          </div>
        </div>
        <div className="scroll-down-arrow">
          <FontAwesomeIcon icon={faChevronDown} />
        </div>
      </div>
      {/* Process */}
      <section className="mil-p-120-60">
        <div className="mil-deco" style={{ top: 0, right: '25%' }}></div>
        <div className="container">
          <span className="mil-suptitle mil-suptitle-2 mil-mb-30">High Experience</span>
          <h2 className="mil-mb-120">
            Development <span className="mil-accent">Services</span> That Set Us Apart
          </h2>
          <div className="row">
            <div className="col-md-6 col-xl-4">
              <div className="mil-icon-box-2 mil-mb-60">
                <div className="mil-icon-frame mil-icon-frame-md mil-icon-bg mil-mb-30">
                  <img src="assets/img/icons/md/20.svg" alt="icon" />
                </div>
                <div className="mil-box-text">
                  <h4 className="mil-mb-30"> app development</h4>
                  <p className="mil-box-text mil-mb-30">
                    We develop native and cross-platform  apps for iOS and Android devices. We work with you to design and develop a  app that meets your specific needs and helps you achieve your business goals.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-4">
              <div className="mil-icon-box-2 mil-mb-60">
                <div className="mil-icon-frame mil-icon-frame-md mil-icon-bg mil-mb-30">
                  <img src="assets/img/icons/md/15.svg" alt="icon" />
                </div>
                <div className="mil-box-text">
                  <h4 className="mil-mb-30">Web development</h4>
                  <p className="mil-box-text mil-mb-30">
                    We develop and maintain high-quality, user-friendly, and responsive websites for businesses of all sizes. We use the latest technologies and best practices to ensure that your website is fast, secure, and easy to navigate.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-4">
              <div className="mil-icon-box-2 mil-mb-60">
                <div className="mil-icon-frame mil-icon-frame-md mil-icon-bg mil
-mb-30">
                  <img src="assets/img/icons/md/11.svg" alt="icon" />
                </div>
                <div className="mil-box-text">
                  <h4 className="mil-mb-30">Custom Software Development</h4>
                  <p className="mil-box-text mil-mb-30">
                    We can develop custom software solutions for your specific needs, taking into account your target audience, business goals, and other factors. We work closely with you throughout the development process to ensure that you are happy with the final product.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <NavLink to="/Contact-Us" className="mil-link">
            <span>Let’s Solve Your Challenges Together</span>
            <i className="fas fa-arrow-right"></i>
          </NavLink>
        </div>
      </section>
      <section className="mil-p-10-60">
        <div className="mil-deco" style={{ top: 0, right: '15%' }}></div>
        <div className="container">
          <span className="mil-suptitle mil-suptitle-2 mil-mb-30">All Devices</span>
          <h2 className="mil-mb-90">
            <span className="mil-accent">End-to-End</span>  Development Services
          </h2>
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-5">
              <ul className="mil-tabs-left-nav mil-mb-60">
                <li>
                  <a  onClick={() => setActiveTab('tab-1')} className={`mil-h4 btn btn-outline ${activeTab === 'tab-1' ? 'mil-active' : ''}`}>
                    Discovery & R&D
                  </a>
                </li>
                <li>
                  <a onClick={() => setActiveTab('tab-2')} className={`mil-h4 btn btn-outline ${activeTab === 'tab-2' ? 'mil-active' : ''}`}>
                    UX/UI Design
                  </a>
                </li>
                <li>
                  <a onClick={() => setActiveTab('tab-3')} className={`mil-h4 btn btn-outline ${activeTab === 'tab-3' ? 'mil-active' : ''}`}>
                    Development
                  </a>
                </li>
                <li>
                  <a onClick={() => setActiveTab('tab-4')} className={`mil-h4 btn btn-outline ${activeTab === 'tab-4' ? 'mil-active' : ''}`}>
                    QA
                  </a>
                </li>
                <li>
                  <a onClick={() => setActiveTab('tab-5')} className={`mil-h4 btn btn-outline ${activeTab === 'tab-5' ? 'mil-active' : ''}`}>
                    Launch
                  </a>
                </li>
                <li>
                  <a onClick={() => setActiveTab('tab-6')} className={`mil-h4 btn btn-outline ${activeTab === 'tab-6' ? 'mil-active' : ''}`}>
                    Maintenance & Support
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-6">
              <div className="mil-tabs-content mil-mb-60">
                {Object.keys(tabContent).map((key) => (
                  <div
                    key={key}
                    className={`mil-tab-content ${activeTab === key ? 'mil-active' : ''}`}
                    style={{ display: activeTab === key ? 'block' : 'none' }}
                  >
                    <div className="mil-icon-frame mil-icon-bg mil-icon-frame-md mil-mb-30">
                      <img src={`assets/img/icons/md/${tabContent[key].icon}`} alt="icon" />
                    </div>
                    <div className="mil-mb-30">
                      <p>{tabContent[key].text}</p>
                    </div>
                    <NavLink to="/Contact-Us" className="mil-link">
                      <span>Contact Us</span>
                      <i className="fas fa-arrow-right"></i>
                    </NavLink>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ServicePage;
