import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Use NavLink instead of Link
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const ServicePage = () => {

    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            setIsVisible((prevVisible) => !prevVisible);
        }, 10000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <div className="mil-wrapper">
            {/* Banner */}
            <div className="mil-banner mil-top-space-0" style={{ height: '500px', position: 'relative' }}>
                <img
                    src="assets/img/deco/hero2.jpg"
                    className={`mil-background-image ${isVisible ? 'zoom-in-fade-in' : 'zoom-out-fade-out'}`}
                    style={{ objectPosition: 'center', height: '500px', position: 'absolute', top: 0, left: 0, width: '100%' }}
                    alt="heroImage" />
                <div className="mil-overlay"></div>
                <div className="mil-banner-content mil-mt-10 ml-12">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-12 ml-10">
                                <h2 className="mil-mb-40">
                                    <span className="mil-uppercase mil-light">Consulting</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="scroll-down-arrow">
                    <FontAwesomeIcon icon={faChevronDown} />
                </div>
            </div>

            {/* Title */}
            <section className="mil-p-120-0">
                <div className="container">
                    <div className="mil-text-center mil-mb-90">
                        <h2>Consulting and Development Company <br /> With <span className="mil-accent">Extensive Know How</span></h2>
                    </div>
                </div>
            </section>

            {/* About */}
            <section className="mil-deep-bg mil-p-120-0">
                <div className="container">
                    <div className="swiper-container mil-tabs-slider">
                        <div className="swiper-wrapper">
                            <div className="swiper-slide" data-hash="tab1">
                                <div className="row justify-content-between align-items-center" data-swiper-parallax-y="-100" data-swiper-parallax-opacity="0">
                                    <div className="col-lg-7">
                                        <div className="mil-hori-box mil-mb-60">
                                            <div className="mil-mr-15">
                                                <div className="mil-icon-frame mil-icon-frame-md">
                                                    <img src="assets/img/icons/md/8.svg" alt="icon" />
                                                </div>
                                            </div>
                                            <h5>Talk To Our Team Of Experts</h5>
                                        </div>
                                        <div className="row mil-mb-60">
                                            <div className="col-lg-6">
                                                <p>Our team of experienced and certified consultants has a deep understanding of the latest technologies and trends, as well as the unique challenges that businesses face in today's competitive marketplace. We work closely with our clients to understand their specific needs and develop customized solutions that help them achieve their goals.</p>
                                            </div>
                                            <div className="col-lg-6">
                                                <p>Zenith Solutions is a leading IT consulting firm that helps businesses of all sizes achieve their goals. We offer a wide range of consulting services.</p>
                                            </div>
                                        </div>
                                        <Link to="/Contact-Us" className="mil-link mil-mb-60"><span>Contact Us</span><i className="fas fa-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <section className="mil-p-120-120">
                <div className="mil-deco" style={{ top: 0, right: '30%' }}></div>
                <div className="container">
                    <div className="row align-items-end mil-mb-40">
                        <div className="col-md-6 col-xl-6">

                            <h2><span className="mil-accent">Industry Specific</span></h2>

                        </div>

                    </div>
                    <div className="swiper-container mil-revi-slider mil-mb-90">
                        <div className="swiper-wrapper row col-md-12">
                            <div className="swiper-slide col-md-4">

                                <div className="mil-hover-card">
                                    <div className="mil-icon-frame mil-icon-frame-md mil-mb-30">
                                        <img src="assets/img/icons/md/12.svg" alt="icon" />
                                    </div>
                                    <h5 className="mil-mb-30">Retail and eCommerce</h5>
                                    <p>Help retailers increase sales and market share by developing and implementing omnichannel strategies, optimizing product listings and search engine rankings, and improving customer experience.</p>
                                </div>

                            </div>

                            <div className="swiper-slide col-md-4">

                                <div className="mil-hover-card">
                                    <div className="mil-icon-frame mil-icon-frame-md mil-mb-30">
                                        <img src="assets/img/icons/md/10.svg" alt="icon" />
                                    </div>
                                    <h5 className="mil-mb-30">Agricultural</h5>
                                    <p>Help agricultural businesses increase crop yields and livestock productivity by implementing sustainable farming practices, using precision agriculture technologies, and optimizing supply chains.</p>
                                </div>

                            </div>

                            <div className="swiper-slide col-md-4">

                                <div className="mil-hover-card">
                                    <div className="mil-icon-frame mil-icon-frame-md mil-mb-30">
                                        <img src="assets/img/icons/md/14.svg" alt="icon" />
                                    </div>
                                    <h5 className="mil-mb-30">Finance and Banking</h5>
                                    <p>Help financial institutions develop and implement new products and services that meet the evolving needs of their customers.</p>
                                    <p>Advising financial institutions on how to comply with complex regulations and manage risk.</p>
                                </div>

                            </div>
                        </div>
                    </div>
                    <Link to="/Contact-Us" className="mil-link"><span>Let’s Solve Your Challenges Together</span><i className="fas fa-arrow-right"></i></Link>
                </div>
            </section>
        </div>
    );
}

export default ServicePage;
