import React from 'react';

function Contact() {
    return (
        <section className="mil-contact mil-mt-30 mil-p-120-30">
            <div className="container">
                <div className="row mil-mt-30  mil-mb-60-adapt">
                    <div className="col-lg-4">
                        <div className="mil-mb-60">
                            <h5 className="mil-list-title mil-mb-30">Call Us</h5>
                            <p className="mil-mb-20">+265 884 939 140</p>
                            <a href="tel:265884939140" className="mil-link mil-link-sm">
                                <span>Call Now</span>
                                <i className="fas fa-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="mil-mb-60">
                            <h5 className="mil-list-title mil-mb-30">Call Us</h5>
                            <p className="mil-mb-20">+265 998 351 834</p>
                            <a href="tel:265998351834" className="mil-link mil-link-sm">
                                <span>Call Now</span>
                                <i className="fas fa-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="mil-mb-60">
                            <h5 className="mil-list-title mil-mb-30">Send Us An Email</h5>
                            <p className="mil-mb-20">Info@zenithsolutionsmw.com</p>
                            <a href="mailto:Info@zenithsolutionsmw.com" className="mil-link mil-link-sm">
                                <span>Send Email Now</span>
                                <i className="fas fa-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Contact;
