import React from 'react'
import Herosection from '../components/HeroSection';
import Services from '../components/Content';

function Home() {
  return (
    <div>
      <Herosection />
      <Services />
    </div>
  )
}

export default Home