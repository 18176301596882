export default function Services() {
    
    return (


    <section className="mil-services mil-p-120-60">
      <div className="mil-deco" style={{ top: 0, right: '20%' }}></div>
      <div className="container">
        <h2 className="mil-mb-30">How We Can <span className="mil-accent">Help You</span></h2>
        <div className="row">
          <div className="col-lg-6 col-xl-6">
            <h4 className="mil-mb-60 mil-mt-30">IT Services</h4>
            <div className="mil-divider mil-divider-left"></div>
            <ServiceItem
              icon="assets/img/icons/md/1.svg"
              number="01"
              title="Web Hosting"
              description="We offer a variety of web hosting plans to meet the needs of businesses of all sizes.
               Our plans include features such as unlimited bandwidth, storage, etc."
            />
            <div className="mil-divider mil-divider-left"></div>
            <ServiceItem
              icon="assets/img/icons/md/16.svg"
              number="02"
              title="IT Consulting"
              description="Our experienced consultants can help you with hardware, software selection, and IT strategy. We provide implementation and ongoing support."
            />
          </div>
          <div className="col-lg-6 col-xl-6">
            <h4 className="mil-mb-60 mil-mt-30">IT Solutions</h4>
            <div className="mil-divider mil-divider-left"></div>
            <ServiceItem
              icon="assets/img/icons/md/11.svg"
              number="01"
              title="Software Development"
              description="Custom software solutions tailored to your business needs. Our experienced developers create custom applications."
            />
            <div className="mil-divider mil-divider-left"></div>
            <ServiceItem
              icon="assets/img/icons/md/5.svg"
              number="02"
              title="Cross Platform Applications"
              description="Mobile apps for iOS, Android, and Windows. We build hybrid apps and desktop apps for multiple operating systems."
            />
          </div>
        </div>
      </div>
    </section>
  );
}
  

  
const ServiceItem = ({ icon, number, title, description }) => (
    <div className="mil-service-item">
      <div className="mil-service-icon">
        <div className="mil-icon-frame mil-icon-frame-md">
          <img src={icon} alt="icon" />
        </div>
      </div>
      <div className="mil-service-text">
        <h5 className="mil-mb-30"><span className="mil-accent">{number}</span> {title}</h5>
        <p>{description}</p>
      </div>
    </div>
  );
  