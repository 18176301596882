import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom'; // Use NavLink instead of Link

export default function HeroSection() {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsVisible((prevVisible) => !prevVisible);
    }, 10000); // Slower transition duration

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="mil-banner mil-top-space-0" style={{ height: '500px', position: 'relative' }}>
      <img
        src="assets/img/deco/hero4.jpg" // Your single background image
        className={`mil-background-image ${isVisible ? 'zoom-in-fade-in' : 'zoom-out-fade-out'}`}
        style={{ objectPosition: 'center', height: '500px', position: 'absolute', top: 0, left: 0, width: '100%' }}
        alt="heroImage"
      />
      <div className="mil-overlay"></div>
      <div className="mil-banner-content mil-mt-10 ml-12">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 ml-10">
              <h2 className="mil-mb-40">
                <span className="mil-uppercase mil-light">
                  Elevate your <span className="mil-accent">Vision</span>
                </span>
              </h2>
              <h5 className="text-light mil-mb-60">
                We strive to reach <span className="mil-accent mil-uppercase">zenith</span>, where possibilities are limitless.
              </h5>
             
              <Link to="/Contact-Us" className="amazing-button">
                Contact Zenith <FontAwesomeIcon icon={faPhone} />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="scroll-down-arrow">
        <FontAwesomeIcon icon={faChevronDown} />
      </div>
      <style jsx>{`
        .zoom-in-fade-in {
          animation: zoom-in-fade-in 20s infinite alternate; // Slower transition
        }
        .zoom-out-fade-out {
          animation: zoom-out-fade-out 20s infinite alternate; // Slower transition
        }
        @keyframes zoom-in-fade-in {
          0% {
            opacity: 0.3; /* Adjust the starting opacity as needed */
            transform: scale(1);
          }
          50% {
            opacity: 1;
            transform: scale(1.1); /* Adjust the zoom level as needed */
          }
          100% {
            opacity: 0.3; /* Smooth out the transition */
            transform: scale(1);
          }
        }
        @keyframes zoom-out-fade-out {
          0% {
            opacity: 1;
            transform: scale(1.1); /* Adjust the zoom level as needed */
          }
          50% {
            opacity: 0.3;
            transform: scale(1);
          }
          100% {
            opacity: 1; /* Smooth out the transition */
            transform: scale(1.1);
          }
        }
      `}</style>
    </div>
  );
}
