import React, { useEffect, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom'; // Use NavLink instead of Link

export default function Header() {
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    setIsMenuOpen(false); // Close menu when route changes
  }, [location.pathname]);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="mil-navigation">
      <nav className={`navbar navbar-expand-lg navbar-light mil-top-position mil-fixed ${isScrolled || isMenuOpen || location.pathname === '/Contact-Us' ? 'bg-white' : ''}`}>
        <div className="container">
          <div className="mil-nav-image">
            <img
              className="mil-logo"
              style={{ width: "130px" }}
              src={`assets/img/logo/${isScrolled || isMenuOpen || location.pathname === '/Contact-Us' ? 'logo-dark.png' : 'logo-light.png'}`}
              alt="Company Logo"
            />
          </div>
          <Link to="/" className="navbar-brand mil-logo"></Link>
          <button
            className={`mil-menu-btn navbar-toggler ${isMenuOpen ? 'open' : ''}`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded={isMenuOpen ? "true" : "false"}
            aria-label="Toggle navigation"
            onClick={handleMenuToggle}
          >
            <span className="navbar-toggler-icon "></span>
          </button>
          <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item mil-has-children">
                <NavLink exact activeClassName="active" className={`nav-link nav-text ${isScrolled || isMenuOpen || location.pathname === '/Contact-Us' ? 'text-dark' : 'text-light'}`} aria-current="page" to="/">Home</NavLink>
              </li>
              {/* Add activeClassName="active" to the NavLink components */}
              <li className="nav-item dropdown mil-has-children">
                <NavLink activeClassName="active" className={`nav-link dropdown-toggle nav-text ${isScrolled || isMenuOpen || location.pathname === '/Contact-Us' ? 'text-dark' : 'text-light'}`} to="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Services
                </NavLink>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li><Link className="dropdown-item" to="/Consulting">Consulting</Link></li>
                  {/* <li><Link className="dropdown-item" to="/Web-hosting">Web Hosting</Link></li> */}
                </ul>
              </li>
              <li className="nav-item dropdown">
                <NavLink activeClassName="active" className={`nav-link dropdown-toggle nav-text ${isScrolled || isMenuOpen || location.pathname === '/Contact-Us' ? 'text-dark' : 'text-light'}`} to="#" id="navbarDropdownSolutions" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Solutions
                </NavLink>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdownSolutions">
                  <li><Link className="dropdown-item" to="/Software-Development">Modern Development</Link></li>
                </ul>
              </li>
              <li className="nav-item">
                <NavLink activeClassName="active" className={`nav-link nav-text ${isScrolled || isMenuOpen || location.pathname === '/Contact-Us' ? 'text-dark' : 'text-light'}`} to="/About-Us">About Us</NavLink>
              </li>
              <li className="nav-item">
                <NavLink activeClassName="active" className={`nav-link nav-text ${isScrolled || isMenuOpen || location.pathname === '/Contact-Us' ? 'text-dark' : 'text-light'}`} to="/Contact-Us">Contact Us</NavLink>
              </li>
              {/* <li className="nav-item mt-2">
                <Link to="#" className="amazing-button">
                  Zenith Store <FontAwesomeIcon className='' icon={faStore} />
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
      <style>{`
        .navbar-toggler {
          border: none;
        }
        .navbar-toggler:focus {
          box-shadow: none;
        }
        .navbar-toggler-icon {
          background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
        }
        .navbar-toggler.open .navbar-toggler-icon {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 4L26 26M26 4L4 26'/%3E%3C/svg%3E");
        }

        /* Style for the active link */
        .navbar-nav .nav-item .nav-link.active {
          color: #your-color-here; /* Specify your desired color */
        }
      `}</style>
    </div>
  );
}
